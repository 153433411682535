import { Button, Modal } from 'antd';
import React from 'react';
import useMyExam from 'src/hooks/useMyExam';

import styles from './index.module.scss';
import { useQuery } from '@apollo/client';
import { GET_SECTIONS } from 'src/services/question-mini-test';
import dayjs from 'dayjs';
import IconClose from 'src/components/icons/IconClose';

const ModalMyExamNotification = () => {
  const { isShow, onCloseModal, data, dataNationalExam } = useMyExam();
  const { data: dataSections } = useQuery(GET_SECTIONS);

  return (
    <Modal
      open={isShow}
      className={styles.modalNotiExam}
      title=''
      footer={false}
      onCancel={() => onCloseModal(false)}
      closeIcon={<IconClose />}
      centered
    >
      {data?.getMyExam?.data?.map((examItem: any) => {
        return (
          <p key={examItem?.id}>
            「
            {dataSections?.sections?.find((it: any) => it?.id === examItem?.sectionId)?.name ?? ''}
            」は{dayjs(examItem?.startDate).format('YYYY')}年
            {dayjs(examItem?.startDate).format('MM')}月{dayjs(examItem?.startDate).format('DD')}日
            {dayjs(examItem?.startDate).format('HH')}時
            {dayjs(examItem?.startDate).format('mm') === '00'
              ? ''
              : `${dayjs(examItem?.startDate).format('mm')}分`}
            から{dayjs(examItem?.endDate).format('YYYY')}年{dayjs(examItem?.endDate).format('MM')}月
            {dayjs(examItem?.endDate).format('DD')}日{dayjs(examItem?.endDate).format('HH')}時
            {dayjs(examItem?.endDate).format('mm') === '00'
              ? ''
              : `${dayjs(examItem?.startDate).format('mm')}分`}
            まで実施されます。
          </p>
        );
      })}
      {dataNationalExam?.getMyNationalExam?.map((examItem: any, idx: number) => {
        return (
          <p key={`nataional-${idx}`}>
            「{examItem?.name ?? ''}
            」は{dayjs(examItem?.startDate).format('YYYY')}年
            {dayjs(examItem?.startDate).format('MM')}月{dayjs(examItem?.startDate).format('DD')}日
            {dayjs(examItem?.startDate).format('HH')}時
            {dayjs(examItem?.startDate).format('mm') === '00'
              ? ''
              : `${dayjs(examItem?.startDate).format('mm')}分`}
            から{dayjs(examItem?.endDate).format('YYYY')}年{dayjs(examItem?.endDate).format('MM')}月
            {dayjs(examItem?.endDate).format('DD')}日{dayjs(examItem?.endDate).format('HH')}時
            {dayjs(examItem?.endDate).format('mm') === '00'
              ? ''
              : `${dayjs(examItem?.startDate).format('mm')}分`}
            まで実施されます。
          </p>
        );
      })}

      <Button onClick={() => onCloseModal(true)} className={styles.btn}>
        再表示しない
      </Button>
    </Modal>
  );
};

export default ModalMyExamNotification;
