import { Layout, Spin, theme } from 'antd';
import { useEffect } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { LOCAL_STORAGE_KEY } from 'src/constants/LOCAL_STORAGE_KEY';
import ModalMyExamNotification from 'src/layouts/MainLayout/ModalMyExamNotification/ModalMyExamNotification';
import { ROUTE_PATH } from 'src/routes/route.constant';
import { getAccessToken } from 'src/store/auth/useAuthStore';
import { useProfileStore } from 'src/store/profile/useProfileStore';
import Header from '../Header';
import Navbar from '../Navbar';
import Sidernav from '../Siderbav';

const { Content, Sider } = Layout;

const MainLayout = () => {
  const isLogin = getAccessToken();
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const { profile } = useProfileStore();

  const history = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (
      localStorage.getItem(LOCAL_STORAGE_KEY.EMAIL_FIRST_LOGIN) &&
      location.pathname !== ROUTE_PATH.CHANGE_PASSWORD
    ) {
      return history(ROUTE_PATH.CHANGE_PASSWORD);
    }

    if (
      !localStorage.getItem(LOCAL_STORAGE_KEY.EMAIL_FIRST_LOGIN) &&
      ROUTE_PATH.CHANGE_PASSWORD === location.pathname
    ) {
      history(ROUTE_PATH.HOME);
    }
  }, [history, location.pathname]);

  if (!isLogin) return <Navigate to={ROUTE_PATH.LOGIN} replace />;

  if (!profile?.id) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Spin />
      </div>
    );
  }

  // if (profile.isUserTest && location.pathname === ROUTE_PATH.MY_PAGE) {
  //   return <Navigate to={ROUTE_PATH.HOME} replace />;
  // }

  return (
    <>
      <Layout
        style={{
          background: colorBgContainer,
          minHeight: '100vh',
          margin: '0 auto',
        }}
      >
        <Header />
        <Layout>
          <Sider
            className='hidden md:block custom-sider-ant'
            style={{ background: colorBgContainer }}
          >
            <Navbar />
          </Sider>
          <Content style={{ background: colorBgContainer }}>
            <Outlet />
          </Content>
        </Layout>
        <Sidernav />
      </Layout>

      <ModalMyExamNotification />
    </>
  );
};

export default MainLayout;
