export const ROUTE_PATH = {
  HOME: '/',
  SUBJECT_QUESTION: '/subject-question',
  EXAMINATION: '/examination',
  TRIAL_EXAMINATION: '/trial-examination/:query',
  AI_TEST: '/AI-test',
  MINI_TEST: '/mini-test/:classId/:formTestId/:sectionId/:sendMiniTestId',
  AI_TEST_BY_SUBJECT: '/AI-test/:id',
  CHAT: '/chat',
  EXPLAIN: '/explain',
  MY_PAGE: '/mypage',
  RESULT: '/result',

  LOGIN: '/login',
  CHANGE_PASSWORD: '/change-password',
  CONFIRM_EMAIL: '/confirm-email',
  CONFIRM_CODE: '/confirm-code',
  CONFIRM_PASS: '/confirm-password',
  EXAMINATION_RADIO: '/examination-test-radio',
};
