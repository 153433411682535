import { atom } from 'jotai';

export interface IProfile {
  id: string;
  identityNumber: string;
  email: string;
  status: string;
  fullName: string;
  isUserTest: boolean;
}

export const profileAtom = atom<Partial<IProfile>>({});
