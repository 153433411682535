import { Col, Row } from 'antd';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { NeedPractice } from 'src/assets/icons/NeedPractice';
import { sortSubject } from 'src/helpers/utils';
import styles from './index.module.scss';
interface IResultTestProps {
  listAnswer: any;
  selfTestResultData?: any;
  handleNavigate: (p: number) => void;
  isShowAnswer?: boolean;
}

const needPractice = (selfTestData: any) => {
  let subjectNeedPractice: any[] = [];
  selfTestData?.map((it: any) => {
    return it?.subjects?.map((subject: any) => {
      subjectNeedPractice.push(subject);
      return subject;
    });
  });
  subjectNeedPractice.reduce((acc: any, it: any) => {
    acc.push(it);
    const pointMediumAccMap = acc.map((item: any) => {
      return {
        mediumPoint: item?.point / item?.maxPoint,
        ...item,
      };
    });
    const filterSubjectNoQuestion = pointMediumAccMap.filter((item: any) => {
      if (!isNaN(item?.mediumPoint)) {
        return item;
      }
    });
    filterSubjectNoQuestion.sort(function (a: any, b: any) {
      return a.mediumPoint < b.mediumPoint ? -1 : a.mediumPoint > b.mediumPoint ? 1 : 0;
    });
    subjectNeedPractice = [
      filterSubjectNoQuestion?.[0]?.mediumPoint <= 0.8 && filterSubjectNoQuestion?.[0]?.subjectId,
      filterSubjectNoQuestion?.[1]?.mediumPoint <= 0.8 && filterSubjectNoQuestion?.[1]?.subjectId,
      filterSubjectNoQuestion?.[2]?.mediumPoint <= 0.8 && filterSubjectNoQuestion?.[2]?.subjectId,
    ];
    return acc;
  }, []);
  return subjectNeedPractice;
};

const ResultTest: React.FunctionComponent<IResultTestProps> = ({
  listAnswer,
  handleNavigate,
  selfTestResultData,
  isShowAnswer,
}) => {
  const history = useNavigate();
  const selfTestData = useMemo(() => {
    const arr = sortSubject(
      (
        selfTestResultData?.selfTrialResult?.selfTests?.[0] ||
        selfTestResultData?.nationalTrialResult
      )?.groups,
    );

    const subjectsArr = arr.map((it: any) => {
      return it?.subjects?.map((subject: any) => {
        return {
          subjectName: subject?.subjectName,
          point: subject?.point,
          ratio: Number.isNaN(subject?.point / subject?.maxPoint)
            ? 0
            : subject?.point / subject?.maxPoint,
        };
      });
    });
    const sortArr = subjectsArr.flat().sort((a: any, b: any) => a.ratio - b.ratio);
    const listItemMin = [sortArr[0], sortArr[1], sortArr[2]];

    return {
      ...(selfTestResultData?.selfTrialResult?.selfTests?.[0] ||
        selfTestResultData?.nationalTrialResult),
      listItemMin,
      groups: arr,
    };
  }, [selfTestResultData]);

  const correctAnswer = selfTestData?.point / 2;
  const percentCorrect = correctAnswer / 55;
  // const totalSubjectPoint = selfTestData.groups.map((subject: any) => {
  //   return subject.subjects.reduce((acc: any, cur: any) => {
  //     return acc + cur.point;
  //   }, 0);
  // });
  // const isMissedZeroPoint = totalSubjectPoint.some((item: any) => item === 0);

  const showTitleCongrats = (percent: number) => {
    // if (isMissedZeroPoint) {
    //   return (
    //     <div className='box-title'>
    //       <div className='box-header box-header-good'>Keep going!</div>
    //       <div className='dot-result  dot-result-good'>
    //         <div className='correct-option'>0</div>
    //         <div className='total-option'>/110</div>
    //       </div>
    //     </div>
    //   );
    // } else {
    switch (true) {
      case percent === 1:
        return (
          <div className='box-title'>
            <div className='box-header box-header-congrats'>Congratulations!</div>
            <div className='dot-result  dot-result-congrats'>
              <div className='correct-option'>{selfTestData?.point || 0}</div>
              <div className='total-option'>/110</div>
            </div>
          </div>
        );
      case percent < 1 && percent >= 0.8:
        return (
          <div className='box-title'>
            <div className='box-header box-header-great'>Great!</div>
            <div className='dot-result dot-result-great'>
              <div className='correct-option'>{selfTestData?.point || 0}</div>
              <div className='total-option'>/110</div>
            </div>
          </div>
        );

      case percent < 0.8 && percent >= 0.7:
        return (
          <div className='box-title'>
            <div className='box-header box-header-great'>Good job!</div>
            <div className='dot-result dot-result-great'>
              <div className='correct-option'>{selfTestData?.point || 0}</div>
              <div className='total-option'>/110</div>
            </div>
          </div>
        );
      case percent < 0.7 && percent >= 0.6:
        return (
          <div className='box-title'>
            <div className='box-header box-header-great'>Well done!</div>
            <div className='dot-result dot-result-great'>
              <div className='correct-option'>{selfTestData?.point || 0}</div>
              <div className='total-option'>/110</div>
            </div>
          </div>
        );
      case percent < 0.6 && percent >= 0.5:
        return (
          <div className='box-title'>
            <div className='box-header box-header-great'>Keep it up!</div>
            <div className='dot-result dot-result-great'>
              <div className='correct-option'>{selfTestData?.point || 0}</div>
              <div className='total-option'>/110</div>
            </div>
          </div>
        );

      default:
        return (
          <div className='box-title'>
            <div className='box-header box-header-good'>Keep going!</div>
            <div className='dot-result  dot-result-good'>
              <div className='correct-option'>{selfTestData?.point || 0}</div>
              <div className='total-option'>/110</div>
            </div>
          </div>
        );
    }
    // }
  };

  const handlePractice = (values: any) => {
    history(`/AI-test/${values.subjectId}`);
  };

  return (
    <div className={styles.resultWrap}>
      {showTitleCongrats(percentCorrect)}

      <Row align={'middle'} justify='center'>
        <div className={styles.isPass}>
          {selfTestData?.isPass ? (
            <p className='text-black-700'>合格</p>
          ) : (
            <p className='text-red-700'>不合格</p>
          )}
        </div>
      </Row>
      {isShowAnswer && (
        <Row gutter={16} className='list-answer'>
          {listAnswer?.submitSelfTrial?.map((item: any, idx: number) => {
            return (
              <Col key={idx} span={12}>
                <div className='col-center' onClick={() => handleNavigate(idx)}>
                  <div className='index-ans'>Q{idx + 1}</div>
                  {item.isCorrect ? (
                    <div className='correct-answer' />
                  ) : (
                    <div className='wrong-answer'>X</div>
                  )}
                </div>
              </Col>
            );
          })}
        </Row>
      )}
      {/* <p className={classNames(styles.boxNote, 'mb-10')}>
        ランキング集計後、問題・解説が確認できます
      </p> */}
      <div className={styles.subjectPoint}>
        <div className={styles.textLeft}>
          <p className={styles.titleSubject}>教科名</p>
          {selfTestData?.groups?.map((it: any, idx: any) => {
            const first = it?.subjects?.[0];
            const second = it?.subjects?.[1];
            return it?.subjects?.length === 2 ? (
              <div className='flex flex-col gap-y-1'>
                <div
                  key={idx}
                  className={`${
                    first?.point === 0 ||
                    second?.point === 0 ||
                    selfTestData?.listItemMin.some(
                      (itMin: any) => itMin.subjectName === first.subjectName && itMin.ratio < 0.8,
                    ) ||
                    selfTestData?.listItemMin.some(
                      (itMin: any) => itMin.subjectName === second.subjectName && itMin.ratio < 0.8,
                    )
                      ? 'text-red-700 font-bold'
                      : 'font-bold'
                  }`}
                >
                  {it?.name}
                </div>
                <div className='flex relative cursor-pointer' onClick={() => handlePractice(first)}>
                  {needPractice(selfTestData?.groups).includes(first?.subjectId) &&
                    (window.innerWidth > 768 ? (
                      <span className='absolute -ml-24'>
                        <NeedPractice />
                      </span>
                    ) : (
                      <span className='absolute -ml-8'>
                        <img
                          src={process.env.PUBLIC_URL + '/images/pasted_mobile.png'}
                          alt=''
                          width={24}
                        />
                      </span>
                    ))}
                  <p
                    className={classNames([
                      'ml-3',
                      `${
                        first?.point === 0 ||
                        selfTestData?.listItemMin.some(
                          (itMin: any) =>
                            itMin.subjectName === first.subjectName && itMin.ratio < 0.8,
                        )
                          ? 'text-red-700'
                          : ''
                      }`,
                    ])}
                  >
                    {'('}&rarr; {`${first?.subjectName}`}
                  </p>
                </div>
                <div
                  className='flex relative cursor-pointer'
                  onClick={() => handlePractice(second)}
                >
                  {needPractice(selfTestData?.groups).includes(second?.subjectId) &&
                    (window.innerWidth > 768 ? (
                      <span className='absolute -ml-24'>
                        <NeedPractice />
                      </span>
                    ) : (
                      <span className='absolute -ml-8'>
                        <img
                          src={process.env.PUBLIC_URL + '/images/pasted_mobile.png'}
                          alt=''
                          width={24}
                        />
                      </span>
                    ))}
                  <p
                    className={classNames([
                      'ml-3',
                      `${
                        second?.point === 0 ||
                        selfTestData?.listItemMin.some(
                          (itMin: any) =>
                            itMin.subjectName === second.subjectName && itMin.ratio < 0.8,
                        )
                          ? 'text-red-700'
                          : ''
                      }`,
                    ])}
                  >
                    {'('}&rarr; {`${second?.subjectName}`}
                  </p>
                </div>
              </div>
            ) : (
              <div className='flex relative cursor-pointer' onClick={() => handlePractice(first)}>
                {needPractice(selfTestData?.groups).includes(first?.subjectId) &&
                  (window.innerWidth > 768 ? (
                    <span className='absolute -ml-24'>
                      <NeedPractice />
                    </span>
                  ) : (
                    <span className='absolute -ml-8'>
                      <img
                        src={process.env.PUBLIC_URL + '/images/pasted_mobile.png'}
                        alt=''
                        width={24}
                      />
                    </span>
                  ))}
                <p
                  className={
                    first?.point === 0 ||
                    selfTestData?.listItemMin.some(
                      (itMin: any) => itMin.subjectName === first.subjectName && itMin.ratio < 0.8,
                    )
                      ? 'text-red-700 font-bold'
                      : styles.subjectName
                  }
                  key={idx}
                >
                  {it?.name}
                </p>
              </div>
            );
          })}
        </div>
        <div className={styles.textRight}>
          <p className={styles.titleSubject}>得点 / 配点</p>
          {selfTestData?.groups?.map((it: any, idx: any) => {
            const first = it?.subjects?.[0];
            const second = it?.subjects?.[1];
            return it?.subjects?.length === 2 ? (
              <div className='flex flex-col gap-y-1'>
                <div
                  key={idx}
                  className={`${
                    first?.point === 0 ||
                    second?.point === 0 ||
                    selfTestData?.listItemMin.some(
                      (itMin: any) => itMin.subjectName === first.subjectName && itMin.ratio < 0.8,
                    ) ||
                    selfTestData?.listItemMin.some(
                      (itMin: any) => itMin.subjectName === second.subjectName && itMin.ratio < 0.8,
                    )
                      ? 'text-red-700 font-bold'
                      : 'font-bold'
                  }`}
                >
                  {`${first?.point + second?.point}/${first?.maxPoint + second?.maxPoint}`}
                </div>
                <p
                  className={
                    first?.point === 0 ||
                    selfTestData?.listItemMin.some(
                      (itMin: any) => itMin.subjectName === first.subjectName && itMin.ratio < 0.8,
                    )
                      ? 'text-red-700 '
                      : ''
                  }
                >
                  {`${first?.point}/${first?.maxPoint}`}
                </p>
                <p
                  className={
                    second?.point === 0 ||
                    selfTestData?.listItemMin.some(
                      (itMin: any) => itMin.subjectName === second.subjectName && itMin.ratio < 0.8,
                    )
                      ? 'text-red-700 '
                      : ''
                  }
                >
                  {`${second?.point}/${second?.maxPoint}`}
                </p>
              </div>
            ) : (
              <p
                className={
                  first?.point === 0 ||
                  selfTestData?.listItemMin.some(
                    (itMin: any) => itMin.subjectName === first.subjectName && itMin.ratio < 0.8,
                  )
                    ? 'text-red-700 font-bold'
                    : styles.subjectName
                }
                key={idx}
              >{`${first?.point}/${first?.maxPoint}`}</p>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ResultTest;
