import { useLocation, useNavigate, useParams } from 'react-router-dom';
import IconClose from 'src/components/icons/IconClose';
import ModalCloseExam from 'src/pages/QuestionTest/ModalCloseExam/ModalCloseExam';
import styles from './index.module.scss';
import { Col, Form, Row, Spin, message, notification } from 'antd';
import { QuestionType } from 'src/pages/QuestionTest/type';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useRef, useState } from 'react';
import {
  CONTINUE_NATIONAL_TEST,
  CONTINUE_UNFINISHED_SELF_TRIAL,
  NATIONAL_TRIAL_RESULT,
  PERFORM_NATIONAL_EXAM,
  SAVE_NATIONAL_TEST,
  SAVE_SELF_TEST,
  SELF_TEST_EXAMINATION,
  SELF_TRIAL_RESULT,
  SUBMIT_NATIONAL_TEST,
  SUBMIT_SELF_TRIAL,
} from 'src/services/examination';
import Question from 'src/pages/QuestionTest/components/Question';
import ResultSelfTest from './ResultSelfTest';
import CountDown from './CountDown';
import ModalPause from './ModalPause';
import { useModalClose } from 'src/store/modalCloseExam/useCloseExam';

const formatBody = (answersList: any[], questionList: QuestionType[]) => {
  if (answersList.length < 54) {
    for (let i = answersList.length; i < 55; i++) {
      const element = `question_${questionList[answersList.length]?.id}_${
        questionList[answersList.length]?.subjectId
      }`;
      answersList.push({
        [element]: [],
      });
    }
  }
  return answersList?.reduce((acc, it) => {
    const keys = Object.keys(it)?.[0];
    const values = it?.[keys];
    const [, questionId, subjectId] = keys?.split('_');
    const questionInQuestionList = questionList?.find((val) => val.id === questionId);
    const answers = questionInQuestionList?.answer?.map((answerItem) => {
      const isCorrect = values?.find((answer: string) => answer === answerItem?.id);
      return {
        answerId: answerItem?.id,
        isCorrect: !!isCorrect,
        questionId: answerItem?.questionId,
      };
    });
    const arrAnswerId: any = answers?.map((item: any) => item?.answerId);
    const answerIndex1 = arrAnswerId.indexOf(values?.[0]);
    const answerIndex2 = arrAnswerId.indexOf(values?.[1]);
    const answerIndex3 = arrAnswerId.indexOf(values?.[2]);
    const answerIndex4 = arrAnswerId.indexOf(values?.[3]);
    const answerIndex = [answerIndex1, answerIndex2, answerIndex3, answerIndex4];
    const userAnswer = answerIndex.filter((it: any) => it !== -1);
    acc.push({
      answers,
      userAnswer: !!userAnswer?.length
        ? [
            answerIndex1 + 1 || null,
            answerIndex2 + 1 || null,
            answerIndex3 + 1 || null,
            answerIndex4 + 1 || null,
          ]
        : [0],
      questionId,
      subjectId,
    });

    return acc;
  }, []);
};

const formatData = (
  selfTrialExam: any,
  continueTrialExam: any,
  nationalExam: any,
  continueNationalTestData: any,
) => {
  let questionData: any[] = [];
  let remainTimes: number = 0;
  if (
    selfTrialExam &&
    selfTrialExam?.selfTrialExamination &&
    selfTrialExam?.selfTrialExamination?.questions &&
    selfTrialExam?.selfTrialExamination?.remainTimes
  ) {
    return {
      questionData: selfTrialExam?.selfTrialExamination?.questions,
      remainTimes: selfTrialExam?.selfTrialExamination?.remainTimes,
    };
  }
  if (
    continueTrialExam &&
    continueTrialExam?.continueUnfinishedSelfTrial &&
    continueTrialExam?.continueUnfinishedSelfTrial?.questions &&
    continueTrialExam?.continueUnfinishedSelfTrial?.remainTimes
  ) {
    return {
      questionData: continueTrialExam?.continueUnfinishedSelfTrial?.questions,
      remainTimes: continueTrialExam?.continueUnfinishedSelfTrial?.remainTimes,
    };
  }
  if (
    nationalExam &&
    nationalExam?.performNationalExam &&
    nationalExam?.performNationalExam?.questions &&
    nationalExam?.performNationalExam?.remainTimes
  ) {
    return {
      questionData: nationalExam?.performNationalExam?.questions,
      remainTimes: nationalExam?.performNationalExam?.remainTimes,
    };
  }
  if (
    continueNationalTestData &&
    continueNationalTestData?.continueNationalTest &&
    continueNationalTestData?.continueNationalTest?.questions &&
    continueNationalTestData?.continueNationalTest?.remainTimes
  ) {
    return {
      questionData: continueNationalTestData?.continueNationalTest?.questions,
      remainTimes: continueNationalTestData?.continueNationalTest?.remainTimes,
    };
  }
  return {
    questionData,
    remainTimes,
  };
};

const TrialExamination = () => {
  const params = useParams();
  const { changeOpenModal, openModal } = useModalClose();
  const caseSelfTest = params?.query?.includes('level');
  const caseContinueSelfTest = params?.query === 'continue-test';
  const caseNationalTest = params?.query === 'national';
  const caseContinueNationalTest = params?.query === 'continue-national-test';
  const [form] = Form.useForm();
  const [selectedQuestion, setSelectedQuestion] = useState(0);
  const levelTest = params?.query?.split('-')?.[1];
  const refCountDown: any = useRef({});
  const [render, setRender] = useState<number>(0);
  const [getQuestionSelfTest, { data, loading, error }] = useLazyQuery(SELF_TEST_EXAMINATION);
  const [submitSelfTrial, { data: submitSelfTrialData, loading: loadingSubmit }] =
    useMutation(SUBMIT_SELF_TRIAL);
  const [selfTestResult, { data: selfTestResultData }] = useLazyQuery(SELF_TRIAL_RESULT);
  const [saveTrialExamination] = useMutation(SAVE_SELF_TEST);
  const [requestContinueUnfinishedSelfTrial, { data: continueUnfinishedSelfTrial }] = useMutation(
    CONTINUE_UNFINISHED_SELF_TRIAL,
  );
  const [
    requestPerformNationalExam,
    { data: performNationalExamData, loading: performNationalExamLoading },
  ] = useMutation(PERFORM_NATIONAL_EXAM);
  const [submitNationalTest, { data: submitNationalTestData, loading: submitNationalTestLoading }] =
    useMutation(SUBMIT_NATIONAL_TEST);
  const [nationalTestResult, { data: nationalTestResultData }] =
    useLazyQuery(NATIONAL_TRIAL_RESULT);
  const [saveNationalTest] = useMutation(SAVE_NATIONAL_TEST);
  const [requestContinueNationalTest, { data: continueNationalTestData }] =
    useMutation(CONTINUE_NATIONAL_TEST);

  const refListAnswer: any = useRef([]);
  const refListAnswerRecords = useRef<Record<string, any>>({});
  const refAnswersFormat: any = useRef([]);
  let navigate = useNavigate();
  let { state } = useLocation();

  useEffect(() => {
    if (caseContinueSelfTest) {
      requestContinueUnfinishedSelfTrial();
    } else if (caseNationalTest) {
      requestPerformNationalExam({
        variables: {
          input: {
            nationalTestId: state?.nationalTestId,
          },
        },
      });
    } else if (caseContinueNationalTest) {
      requestContinueNationalTest({
        variables: {
          input: {
            nationalTestId: state?.nationalTestId,
          },
        },
      });
    } else {
      getQuestionSelfTest({
        variables: {
          data: {
            level: Number(levelTest),
          },
        },
      });
    }
  }, [params?.query]);

  useEffect(() => {
    if (error) {
      notification.error({
        message: '',
        description: '全てのテストが終了されました',
      });
      navigate(-1);
    }
  }, [error]);

  useEffect(() => {
    changeOpenModal(true);
    return () => {
      changeOpenModal(false);
    };
  }, []);

  const examinationData = formatData(
    data,
    continueUnfinishedSelfTrial,
    performNationalExamData,
    continueNationalTestData,
  );
  const questionData: any[] = examinationData?.questionData;

  const remainTimes = examinationData?.remainTimes;

  const selfTrialTestId =
    data && data?.selfTrialExamination
      ? data?.selfTrialExamination?.selfTrialTestId
      : continueUnfinishedSelfTrial?.continueUnfinishedSelfTrial?.selfTrialId;

  const nationalTestId =
    performNationalExamData && performNationalExamData?.performNationalExam
      ? performNationalExamData?.performNationalExam?.nationalTestId
      : continueNationalTestData &&
        continueNationalTestData?.continueNationalTest &&
        continueNationalTestData?.continueNationalTest?.nationalTestId;

  useEffect(() => {
    if (caseContinueSelfTest) {
      const answerList = continueUnfinishedSelfTrial?.continueUnfinishedSelfTrial?.answers?.filter(
        (answer: any) => {
          if (answer?.isCorrect) {
            return answer;
          }
        },
      );
      const findQuestionHaveAnswer = questionData?.filter((question: any) => {
        const questionInAnswer = answerList?.map((item: any) => item?.questionId);
        if (questionInAnswer?.includes(question?.id)) {
          return question;
        }
      });

      const answerSaved = findQuestionHaveAnswer?.map((item: any, index: number) => {
        const keys = `question_${item?.id}_${item?.subjectId}`;
        refListAnswerRecords.current = {
          ...refListAnswerRecords.current,
          [item?.id]: {
            [keys]: [answerList?.[index]?.answerId],
          },
        };
        return {
          [keys]: [answerList?.[index]?.answerId],
        };
      });
      answerSaved?.map((it: any) => {
        return refListAnswer.current?.push(it);
      });
    }
    if (caseContinueNationalTest) {
      const answerList = continueNationalTestData?.continueNationalTest?.answers?.filter(
        (answer: any) => {
          if (answer?.isCorrect) {
            return answer;
          }
        },
      );
      const findQuestionHaveAnswer = questionData?.filter((question: any) => {
        const questionInAnswer = answerList?.map((item: any) => item?.questionId);
        if (questionInAnswer?.includes(question?.id)) {
          return question;
        }
      });

      const answerSaved = findQuestionHaveAnswer?.map((item: any, index: number) => {
        let answerArr: any[] = [];
        const keys = `question_${item?.id}_${item?.subjectId}`;
        answerList.map((it: any) => {
          if (it?.questionId === item?.id) {
            answerArr.push(it?.answerId);
          }
        });
        if (item?.id)
          refListAnswerRecords.current = {
            ...refListAnswerRecords.current,
            [item?.id]: {
              [keys]: answerArr,
            },
          };
        return {
          [keys]: answerArr,
        };
      });
      answerSaved?.map((it: any) => {
        return refListAnswer.current?.push(it);
      });
    }
    setRender(Date.now());
  }, [params?.query, questionData, continueUnfinishedSelfTrial, form, continueNationalTestData]);

  const nextStep = async (questionSelected?: QuestionType) => {
    if (refAnswersFormat?.current?.length) {
      const allValues = form.getFieldsValue();
      await refListAnswer.current.splice(selectedQuestion, 1, allValues);
      refAnswersFormat.current = await formatBody(refListAnswer.current, questionData);

      setSelectedQuestion(55);
      return;
    }

    if (questionSelected && !refAnswersFormat.current?.length) {
      const allValues = form.getFieldsValue();
      if (refListAnswer.current?.[selectedQuestion]) {
        refListAnswer.current.splice(selectedQuestion, 1, allValues);
      } else {
        refListAnswer.current.push(allValues);
      }
    }
    if (selectedQuestion + 1 === 55 && !refAnswersFormat.current?.length) {
      refAnswersFormat.current = formatBody(refListAnswer.current, questionData);
      setSelectedQuestion(55);
      return;
    }
    setSelectedQuestion(selectedQuestion + 1);
  };

  const backStep = () => {
    setSelectedQuestion(selectedQuestion - 1);
  };

  const onGetResult = async () => {
    if (caseSelfTest || caseContinueSelfTest) {
      await selfTestResult({
        variables: {
          input: {
            selfTrialId: selfTrialTestId,
          },
        },
        onCompleted() {
          setSelectedQuestion(questionData.length);
        },
      });
    } else {
      await nationalTestResult({
        variables: {
          input: {
            nationalTestId: nationalTestId,
          },
        },
        onCompleted() {
          setSelectedQuestion(questionData.length);
        },
      });
    }
  };

  const onSubmitTrialExamination = async () => {
    if (refCountDown?.current?.onStop) {
      refCountDown.current.onStop();
    }
    if (refListAnswer.current.length !== 55) {
      refAnswersFormat.current = await formatBody(refListAnswer.current, questionData);
    }
    const bodyRequest = await refAnswersFormat.current?.map((item: any) => {
      return {
        answers: item.answers,
        questionId: item?.questionId,
        subjectId: item?.subjectId,
      };
    });
    try {
      if (caseSelfTest || caseContinueSelfTest) {
        await submitSelfTrial({
          variables: {
            data: {
              selfTrialId: selfTrialTestId,
              questions: bodyRequest,
            },
          },
          onCompleted: () => {
            onGetResult();
          },
        });
      }
      if (caseNationalTest || caseContinueNationalTest) {
        await submitNationalTest({
          variables: {
            data: {
              nationalTestId: nationalTestId,
              questions: bodyRequest,
            },
          },
          onCompleted: () => {
            onGetResult();
          },
        });
      }
    } catch (error) {}
    changeOpenModal(false);
  };
  const handleSaveExamination = async () => {
    if (refListAnswer.current.length !== 55) {
      refAnswersFormat.current = await formatBody(refListAnswer.current, questionData);
    }

    const bodyRequest = await refAnswersFormat.current?.map((item: any) => {
      return {
        answers: item.answers,
        questionId: item?.questionId,
        subjectId: item?.subjectId,
      };
    });

    if (caseNationalTest || caseContinueNationalTest) {
      saveNationalTest({
        variables: {
          input: {
            questions: bodyRequest,
            nationalTestId: nationalTestId,
          },
        },
        onCompleted: () => {
          message.success('回答内容が保存されました');
        },
        onError: () => {
          message.error('Ops, Save error!');
        },
      });
    } else {
      saveTrialExamination({
        variables: {
          input: {
            questions: bodyRequest,
            selfTrialId: Number(selfTrialTestId),
          },
        },
        onCompleted: () => {
          message.success('回答内容が保存されました');
        },
        onError: () => {
          message.error('Ops, Save error!');
        },
      });
    }
  };

  if (loading || loadingSubmit || performNationalExamLoading || submitNationalTestLoading)
    return (
      <Row align={'middle'} justify='center'>
        <Spin />
      </Row>
    );
  if (!questionData?.length) return null;

  const conditionShowListAnswer =
    caseNationalTest || caseContinueNationalTest
      ? selectedQuestion === questionData?.length && !submitNationalTestData
      : selectedQuestion === questionData?.length && !submitSelfTrialData;

  return (
    <>
      <div className={styles.wrap}>
        <div>
          {!refAnswersFormat.current?.length || conditionShowListAnswer ? (
            <ModalCloseExam>
              <span className={styles.btnClose}>
                <IconClose />
              </span>
            </ModalCloseExam>
          ) : (
            // <div className={styles.btnClose}>
            <span className={styles.btnClose} onClick={() => navigate('/')}>
              <IconClose />
            </span>
            // </div>
          )}
          <div className='flex direction-row justify-between'>
            {!state?.timeRestriction ? (
              <CountDown
                ref={refCountDown}
                caseNationalTest={caseNationalTest}
                remainTimes={remainTimes}
                isHaveDataSubmit={!!submitSelfTrialData || !!submitNationalTestData}
                onStop={() => {
                  onSubmitTrialExamination();
                }}
              />
            ) : (
              <div />
            )}

            {selectedQuestion !== questionData.length &&
              (!submitSelfTrialData || !submitNationalTestData) &&
              openModal && (
                <ModalPause onSave={() => handleSaveExamination()}>
                  <div className={styles.btnPause}>保存して中断する</div>
                </ModalPause>
              )}
          </div>
          <Form form={form} className={styles.formQuestion} key={render}>
            {questionData?.map((question: QuestionType, idx: any) => (
              <Question
                reload={true}
                key={question?.id}
                notShowExplain={!submitSelfTrialData}
                dataSubmit={!!submitSelfTrialData}
                isShow={idx === selectedQuestion}
                question={question}
                keyIdx={idx + 1}
                oldAnswer={refListAnswerRecords?.current?.[question?.id]}
                nextStep={nextStep}
                backStep={backStep}
                form={form}
                isTest
              />
            ))}
          </Form>
        </div>
        {conditionShowListAnswer && (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Row gutter={16} className='list-answer'>
              {refAnswersFormat.current?.map((item: any, idx: number) => {
                return (
                  <Col key={idx} span={12}>
                    <div className='col-center flex gap-2' onClick={() => setSelectedQuestion(idx)}>
                      <div className='answer-box'>
                        <div className='index-ans'>Q{idx + 1}</div>
                        <span>
                          {item?.userAnswer?.some((it: any) => it === 0)
                            ? '未回答'
                            : item?.userAnswer?.map((it: any) => it).join(' ')}
                        </span>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
            <div className='btn-result'>
              <button
                onClick={() => {
                  onSubmitTrialExamination();
                }}
              >
                模擬試験を終了する
              </button>
            </div>
          </div>
        )}
        {(submitSelfTrialData || submitNationalTestData) &&
          selectedQuestion === questionData.length &&
          (selfTestResultData || nationalTestResultData) && (
            <ResultSelfTest
              isShowAnswer={!caseNationalTest}
              listAnswer={submitSelfTrialData ?? []}
              selfTestResultData={selfTestResultData || nationalTestResultData}
              handleNavigate={(idx) => setSelectedQuestion(idx)}
            />
          )}
      </div>
    </>
  );
};

export default TrialExamination;
