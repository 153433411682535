import ExaminationIcon from '../assets/icons/Examination';
import HomePageIcon from '../assets/icons/Homepage';
import MyPageIcon from '../assets/icons/Mypage';

export const MENU = [
  {
    name: 'ホーム',
    img: <HomePageIcon />,
    path: '/',
  },
  {
    name: 'テスト',
    img: <ExaminationIcon />,
    path: '/examination',
  },

  {
    name: 'マイページ',
    img: <MyPageIcon />,
    path: '/mypage',
  },
];
