import { useCountdown } from 'src/hooks/useCountDown';
import styles from './index.module.scss';
import { forwardRef, useEffect, useImperativeHandle } from 'react';

const CountDown = forwardRef((props: any, ref: any) => {
  const { remain, setIsCounting, isCounting } = useCountdown({
    startDate: new Date(),
    endDate: new Date(new Date().getTime() + props.remainTimes * 1000),
    onStop: () => {
      setIsCounting(false);
      props.onStop();
    },
  });

  useEffect(() => {
    if (props.isHaveDataSubmit) {
      setIsCounting(false);
    }
  }, [props.isHaveDataSubmit]);

  useImperativeHandle(ref, () => {
    return {
      onStop: () => setIsCounting(false),
    };
  });

  if (!isCounting) return null;
  return (
    <div className={styles.countDown}>
      <span>制限時間</span>
      <span
        className={styles.time}
      >{`${remain.hours} : ${remain.minutes} : ${remain.seconds}`}</span>
    </div>
  );
});

export default CountDown;
