import { atom, useAtom } from 'jotai';

const openModalClose = atom(false);
const objectTest = atom({
  loading: true,
  value: [],
});

export const useModalClose = () => {
  const [openModal, setOpenModal] = useAtom(openModalClose);
  const [finishTest, setFinishTest] = useAtom(objectTest);

  const changeOpenModal = (value: boolean) => {
    setOpenModal(value);
  };

  const changeValueTest = (value: any) => {
    setFinishTest(value);
  };

  return {
    openModal,
    changeOpenModal,
    finishTest,
    changeValueTest,
  };
};
