import React, { useRef } from 'react';
import './index.scss';
import { MENU } from '../../constants/COMMON_CONSTANTS';
import { useLocation, useNavigate } from 'react-router-dom';
import ModalCloseExam from 'src/pages/QuestionTest/ModalCloseExam/ModalCloseExam';
import { useModalClose } from 'src/store/modalCloseExam/useCloseExam';
import { useProfileStore } from 'src/store/profile/useProfileStore';
import { ROUTE_PATH } from 'src/routes/route.constant';

interface Menu {
  name: string;
  img: JSX.Element;
  path: string;
}

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { openModal } = useModalClose();
  const refModal: any = useRef();
  const { profile } = useProfileStore();

  const onGoto = (path: string) => () => {
    if (
      (location.pathname?.includes('/AI-test') ||
        location.pathname?.includes('/mini-test') ||
        location.pathname?.includes('/trial-examination')) &&
      openModal
    ) {
      refModal?.current?.setSubmit(() => navigate(path));
      refModal?.current?.onOpen();
      return;
    }
    navigate(path);
  };

  return (
    <>
      <nav className='nav'>
        <ul>
          {MENU.map((el: Menu) => {
            if (profile.isUserTest && el.path === ROUTE_PATH.MY_PAGE) return null;

            return (
              <li key={el.name}>
                <div onClick={onGoto(el.path)}>
                  <div className='flex justify-center items-center mb-1'>{el.img}</div>
                  <span>{el.name}</span>
                </div>
              </li>
            );
          })}
        </ul>
      </nav>
      <ModalCloseExam ref={refModal} />
    </>
  );
};

export default Navbar;
