import { Modal } from 'antd';
import React, { useState } from 'react';
import styles from './index.module.scss';

interface IPropsModalImageQuestion {
  children: React.ReactNode;
  imgUrl?: string;
  videoUel?: string;
}

const ModalImageQuestion = ({ children, imgUrl }: IPropsModalImageQuestion) => {
  const [visible, setVisible] = useState(false);

  const onVisible = () => {
    setVisible(!visible);
  };

  return (
    <>
      <div onClick={onVisible}>{children}</div>

      <Modal
        className={styles.modalClose}
        title=''
        open={visible}
        footer={false}
        onCancel={onVisible}
        closeIcon={<></>}
        centered
      >
        {imgUrl && <img src={imgUrl} alt='' />}
      </Modal>
    </>
  );
};

export default ModalImageQuestion;
