import React from 'react'

const ShampooIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30.537" height="44.798" viewBox="0 0 30.537 44.798">
      <g id="Group_1435" data-name="Group 1435" transform="translate(-4850.582 -300.421)">
        <path id="Path_5309" data-name="Path 5309" d="M4877.289,306.207v-3.021a2.773,2.773,0,0,0-2.765-2.765h-1.933a2.773,2.773,0,0,0-2.765,2.765v3.021Z" fill="#a5a072" />
        <path id="Path_5310" data-name="Path 5310" d="M4854.475,309.919a3.926,3.926,0,0,1,2.4-.538v1.089h1.97v2.868h-.838a1.136,1.136,0,0,0-1.132,1.133v2.26h6.567v-2.26a1.136,1.136,0,0,0-1.132-1.133h-.838V310.47h1.97v-2.717a1.135,1.135,0,0,0-1.132-1.132h-4.3a1.134,1.134,0,0,0-1.13,1.094,5.358,5.358,0,0,0-3.475.935,2.724,2.724,0,0,0-.956,2.153h1.661A1.055,1.055,0,0,1,4854.475,309.919Z" fill="#a5a072" />
        <path id="Path_5311" data-name="Path 5311" d="M4863.913,318.1h-7.423a5.926,5.926,0,0,0-5.908,5.908v20.072a1.139,1.139,0,0,0,1.136,1.136h16.966a1.14,1.14,0,0,0,1.137-1.136V324.011A5.926,5.926,0,0,0,4863.913,318.1Zm3.548,24.662h-14.519V323.917a3.552,3.552,0,0,1,3.548-3.549h7.423a3.553,3.553,0,0,1,3.548,3.549Z" fill="#a5a072" />
        <rect id="Rectangle_331" data-name="Rectangle 331" width="10.225" height="4.572" rx="0.787" transform="translate(4855.089 326.995)" fill="#a5a072" />
        <g id="Group_1434" data-name="Group 1434">
          <path id="Path_5312" data-name="Path 5312" d="M4876.321,317.478h-7.554a8.079,8.079,0,0,1,2.994,4.572h4.56a.789.789,0,0,0,.787-.787v-3A.789.789,0,0,0,4876.321,317.478Z" fill="#a5a072" />
          <path id="Path_5313" data-name="Path 5313" d="M4877.247,307.5h-7.379a4.858,4.858,0,0,0-3.872,4.742v3.877a8.016,8.016,0,0,1,2.359,1.065v-4.942a2.493,2.493,0,0,1,1.792-2.382h6.821a2.492,2.492,0,0,1,1.792,2.382v30.614h-6.774v2.36h9.133V312.245A4.858,4.858,0,0,0,4877.247,307.5Z" fill="#a5a072" />
        </g>
      </g>
    </svg>
  )
}

export default ShampooIcon