import { Modal, Row } from 'antd';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './index.module.scss';

const ModalPause = (
  { children, onSave }: { children?: React.ReactNode; onSave: any },
  ref: any,
) => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);

  useImperativeHandle(ref, () => {
    return {
      onOpen: () => setVisible(true),
    };
  });

  const onVisible = () => {
    setVisible(!visible);
  };

  const renderFooter = () => {
    return (
      <Row className={styles.btnActions}>
        <div
          className={styles.buttonModal}
          onClick={async () => {
            await onSave();
            onVisible();
            navigate('/');
          }}
        >
          保存して中断する
        </div>
        <div className={styles.buttonModal} onClick={onVisible}>
          キャンセル
        </div>
      </Row>
    );
  };

  return (
    <>
      {children && <span onClick={onVisible}>{children}</span>}
      <Modal
        className={styles.modalPause}
        title=''
        open={visible}
        footer={renderFooter()}
        onCancel={onVisible}
        closeIcon={<></>}
        centered
      >
        <p>模擬試験を一時中断しますか？</p>
        <p>現在の回答内容が保存されます。</p>
      </Modal>
    </>
  );
};

export default forwardRef(ModalPause);
