import React from 'react'

const Menu = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25.282" height="24.278" viewBox="0 0 25.282 24.278">
      <g id="Group_977" data-name="Group 977" transform="translate(-3776.09 -58.912)">
        <rect id="Rectangle_321" data-name="Rectangle 321" width="25.282" height="4.287" rx="2.143" transform="translate(3776.09 58.912)" fill="#a5a072" />
        <rect id="Rectangle_322" data-name="Rectangle 322" width="25.282" height="4.287" rx="2.143" transform="translate(3776.09 68.908)" fill="#a5a072" />
        <rect id="Rectangle_323" data-name="Rectangle 323" width="25.282" height="4.287" rx="2.143" transform="translate(3776.09 78.903)" fill="#a5a072" />
      </g>
    </svg>
  )
}

export default Menu