import { useLazyQuery } from '@apollo/client';
import { useAtom } from 'jotai';
import { GET_INFO } from 'src/services/respository/useQueries';
import { getAccessToken } from '../auth/useAuthStore';
import { profileAtom } from './profile';

export const useProfileStore = () => {
  const [profile, setProfile] = useAtom(profileAtom);
  const [getInfoUser] = useLazyQuery(GET_INFO);

  const requestGetProfile = async () => {
    try {
      const isLogin = getAccessToken();
      const localData = localStorage.getItem('lmsShowNoti');

      if (!isLogin) return;

      const profileData = await getInfoUser();

      if (profileData?.data?.me) {
        setProfile((prev) => {
          return {
            ...prev,
            ...profileData?.data?.me,
          };
        });
      }

      if (localData && localData !== profileData?.data?.me?.id) {
        localStorage.removeItem('lmsShowNoti');
      }

      return profileData;
    } catch (error) {}
  };

  return {
    profile,
    requestGetProfile,
  };
};
