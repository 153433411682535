import { useMutation } from '@apollo/client';
import { notification } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import ModalCloseExam from 'src/pages/QuestionTest/ModalCloseExam/ModalCloseExam';
import { ROUTE_PATH } from 'src/routes/route.constant';
import { useAuthStore } from 'src/store/auth/useAuthStore';
import { useModalClose } from 'src/store/modalCloseExam/useCloseExam';
import { useNavbar } from 'src/store/navbar/useNavbarStore';
import { useProfileStore } from 'src/store/profile/useProfileStore';
import ArrowRight from '../../assets/icons/ArrowRight';
import { MENU } from '../../constants/COMMON_CONSTANTS';
import usePrevious from '../../hooks/usePrevious';
import { logoutMutation } from '../../services/respository/useMutations';
import './index.scss';

interface Menu {
  name: string;
  img: JSX.Element;
  path: string;
}

const Sidernav = () => {
  let history = useNavigate();
  const { pathname } = useLocation();
  const { openModal } = useModalClose();
  const refModal: any = useRef();
  const { showNavbar, onVisibleNavbar } = useNavbar();
  const [currentPath, setCurrentPath] = useState<string>();
  const [logout] = useMutation(logoutMutation);
  const oldPath = usePrevious(currentPath);
  const { profile } = useProfileStore();

  const { onLogout: logoutLocalstore } = useAuthStore();

  useEffect(() => {
    setCurrentPath(pathname);
    if (showNavbar && oldPath !== currentPath) {
      onVisibleNavbar();
    }
  }, [pathname, currentPath, oldPath, showNavbar, onVisibleNavbar]);

  const onLogout = () => {
    if (profile.id) {
      logout({
        variables: {
          accountId: profile.id,
        },
      })
        .then((res) => {
          if (res.data.logout.loggedOut) {
            onVisibleNavbar();
            logoutLocalstore();
            history('/login');
          }
        })
        .catch((error) => {
          notification.error({
            message: '',
            description: 'Logout unsuccessful',
            placement: 'topRight',
          });
        });
    }
  };

  const onGoto = (path: string) => () => {
    if (
      (pathname?.includes('/AI-test') ||
        pathname?.includes('/mini-test') ||
        pathname?.includes('/trial-examination')) &&
      openModal
    ) {
      refModal?.current?.setSubmit(() => history(path));
      refModal?.current?.onOpen();
      return;
    }
    history(path);
  };

  return (
    <nav className={`sider ${showNavbar ? 'translate-x-0' : ' translate-x-full'}`}>
      <div className='flex justify-end mt-4 mr-4' onClick={onVisibleNavbar}>
        <ArrowRight />
      </div>
      <ul>
        {MENU.map((el: Menu) => {
          if (profile.isUserTest && el.path === ROUTE_PATH.MY_PAGE) return null;

          return (
            <li key={el.name}>
              <div onClick={onGoto(el.path)} className='flex justify-start gap-5 items-center'>
                <div>{el.img}</div>
                <span>{el.name}</span>
              </div>
            </li>
          );
        })}
      </ul>
      <p className='logout' onClick={onLogout}>
        ログアウト
      </p>
      <ModalCloseExam ref={refModal} />
    </nav>
  );
};

export default Sidernav;
