import React from 'react'

const HairDryerIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="43.043" height="41.648" viewBox="0 0 43.043 41.648">
      <g id="Group_1442" data-name="Group 1442" transform="translate(-4854.352 -449.627)">
        <path id="Path_5341" data-name="Path 5341" d="M4854.352,453.006v13.186l7.521-1.849v-9.488Z" fill="#a5a072" />
        <g id="Group_1441" data-name="Group 1441">
          <path id="Path_5342" data-name="Path 5342" d="M4892.992,482.622a4.41,4.41,0,0,0-4.4,4.16.6.6,0,0,0-.006.089,3.208,3.208,0,0,1-6.314.8,3.713,3.713,0,0,0,3.041-3.639V472.3a3.766,3.766,0,0,0-.052-.612,8.84,8.84,0,0,0,3.761-3.372,9.093,9.093,0,0,1-13.426,1.879,8.812,8.812,0,0,0,2.2,1.493,3.66,3.66,0,0,0-.052.612v1.02h-.372a.537.537,0,0,0-.535.536v1.07a.537.537,0,0,0,.535.535h.372v.662h-.372a.537.537,0,0,0-.535.535v1.07a.537.537,0,0,0,.535.536h.372v5.774a3.711,3.711,0,0,0,3.311,3.678,4.4,4.4,0,0,0,8.718-.6.49.49,0,0,0,.007-.089,3.207,3.207,0,1,1,6.414,0,.6.6,0,0,0,1.2,0A4.409,4.409,0,0,0,4892.992,482.622Z" fill="#a5a072" />
          <path id="Path_5343" data-name="Path 5343" d="M4889.181,468.03c.124-.218.253-.43.358-.658C4889.423,467.594,4889.314,467.819,4889.181,468.03Z" fill="#a5a072" />
        </g>
        <path id="Path_5344" data-name="Path 5344" d="M4882.613,449.63a16.368,16.368,0,0,0-4.512.55c-3.668,1.114-6.8,4.278-12.759,4.72h-1.161v9.468l6.439.546a11.827,11.827,0,0,0,6.262,7.2,21.32,21.32,0,0,0,5.856,1.409c1.475,0,4.189-1.178,4.149-1.409a11.808,11.808,0,0,0-4.274-22.481Zm7.118,11.308H4884c-.014-.062-.03-.123-.049-.183l4.962-2.866A7.811,7.811,0,0,1,4889.731,460.938Zm-1.288-3.86-4.967,2.866c-.042-.046-.088-.09-.134-.133l2.865-4.963A7.9,7.9,0,0,1,4888.443,457.078Zm-7.035-3.51V459.3c-.063.014-.124.03-.184.049l-2.865-4.962A7.784,7.784,0,0,1,4881.408,453.568Zm-3.86,1.288,2.868,4.967a1.724,1.724,0,0,0-.134.135l-4.964-2.867a7.9,7.9,0,0,1,1.946-2.025c.1-.052.195-.118.3-.166h-.074C4877.51,454.886,4877.528,454.87,4877.548,454.856Zm-2.7,3.047,4.964,2.866c-.019.06-.034.122-.048.185h-5.73A7.833,7.833,0,0,1,4874.85,457.9Zm-.812,3.988h5.733c.013.062.029.124.048.184l-4.962,2.865A7.828,7.828,0,0,1,4874.038,461.891Zm1.289,3.86,4.966-2.868c.043.047.088.091.135.134l-2.866,4.964A7.907,7.907,0,0,1,4875.327,465.751Zm6.1,3.511a7.816,7.816,0,0,1-3.051-.813l2.867-4.964a1.863,1.863,0,0,0,.184.047Zm.921-15.7a7.805,7.805,0,0,1,3.05.814l-2.865,4.963c-.061-.019-.123-.034-.185-.047Zm.016,15.695v-5.733c.062-.014.124-.03.184-.049l2.865,4.963A7.835,7.835,0,0,1,4882.361,469.261Zm3.861-1.289-2.868-4.966c.046-.043.091-.088.133-.135l4.964,2.866A7.9,7.9,0,0,1,4886.222,467.972Zm2.7-3.047-4.964-2.866a1.765,1.765,0,0,0,.048-.185h5.73A7.828,7.828,0,0,1,4888.919,464.925Z" fill="#a5a072" />
      </g>
    </svg>
  )
}

export default HairDryerIcon