import { gql } from '@apollo/client';

export const SELF_TEST_EXAMINATION = gql`
  query SelfTrialQuestionDto($data: SelfTrialExaminationInput!) {
    selfTrialExamination(data: $data) {
      questions {
        accountId
        description
        answer {
          explainImageOrVideo
          explainText
          id
          isCorrect
          questionId
          text
        }
        category {
          id
          name
        }
        categoryId
        chapterId
        id
        identityNumber
        level
        public
        description
        file
        schoolId
        status
        subjectId
        title
      }
      remainTimes
      selfTrialTestId
    }
  }
`;

export const SUBMIT_SELF_TRIAL = gql`
  mutation SUBMIT_SELF_TRIAL($data: SubmitSelfTrialQuestionInput!) {
    submitSelfTrial(data: $data) {
      isCorrect
      question {
        answer {
          explainImageOrVideo
          explainText
          id
          isCorrect
          questionId
          text
        }
        id
        title
        description
      }
    }
  }
`;

export const SELF_TRIAL_RESULT = gql`
  query SELF_TRIAL_RESULT($input: SelfTrialInput!) {
    selfTrialResult(input: $input) {
      selfTests {
        groups {
          name
          subjects {
            maxPoint
            point
            subjectName
            totalQuestion
            subjectId
          }
        }
        isPass
        point
        updatedAt
      }
      total
    }
  }
`;

export const SAVE_SELF_TEST = gql`
  mutation SAVE_SELF_TEST($input: SaveSelfTrialInput!) {
    saveSelfTrial(input: $input)
  }
`;

export const HAVE_UNFINISHED_SELF_TEST = gql`
  query HAVE_UNFINISHED_SELF_TEST {
    haveUnfinishedSelfTrial
  }
`;

export const CONTINUE_UNFINISHED_SELF_TRIAL = gql`
  mutation CONTINUE_UNFINISHED_SELF_TRIAL {
    continueUnfinishedSelfTrial {
      answers {
        answerId
        isCorrect
        questionId
      }
      questions {
        accountId
        answer {
          createdAt
          deletedAt
          explainImageOrVideo
          explainText
          id
          isCorrect
          questionId
          text
          updatedAt
        }
        categoryId
        chapterId
        createdAt
        deletedAt
        description
        file
        id
        identityNumber
        level
        no
        public
        schoolId
        status
        subject {
          chapter {
            category {
              id
              name
            }
            id
            name
          }
          code
          id
          name
        }
        subjectId
        title
        updatedAt
      }
      remainTimes
      selfTrialId
    }
  }
`;

export const GET_MY_NATIONAL_EXAM = gql`
  query GET_MY_NATIONAL_EXAM {
    getMyNationalExam {
      endDate
      name
      nationalTestId
      remainTimes
      startDate
      finished
      timeRestriction
      testManually
    }
  }
`;

export const PERFORM_NATIONAL_EXAM = gql`
  mutation PERFORM_NATIONAL_EXAM($input: PerformNationalTestInput!) {
    performNationalExam(input: $input) {
      nationalTestId
      formTestName
      questions {
        answer {
          explainImageOrVideo
          explainText
          id
          questionId
          text
        }
        description
        id
        identityNumber
        subjectId
        level
        title
        numberOfCorrect
        file
      }
      remainTimes
    }
  }
`;

export const SUBMIT_NATIONAL_TEST = gql`
  mutation SUBMIT_NATIONAL_TEST($data: SubmitNationalTestQuestionInput!) {
    submitNationalTest(data: $data) {
      isCorrect
      question {
        accountId
        answer {
          createdAt
          deletedAt
          explainImageOrVideo
          explainText
          id
          isCorrect
          questionId
          text
          updatedAt
        }
        categoryId
        chapterId
        createdAt
        deletedAt
        description
        file
        id
        identityNumber
        level
        no
        public
        schoolId
        status
        subject {
          chapter {
            category {
              id
              name
            }
            id
            name
          }
          code
          id
          name
        }
        subjectId
        title
        updatedAt
      }
    }
  }
`;

export const NATIONAL_TRIAL_RESULT = gql`
  query NATIONAL_TRIAL_RESULT($input: NationalTestPaginationInput!) {
    nationalTrialResult(input: $input) {
      groups {
        name
        subjects {
          maxPoint
          point
          subjectId
          subjectName
          totalQuestion
        }
      }
      id
      accountId
      isPass
      nationalRank
      nationalTestId
      point
      totalPoint
      totalStudent
      updatedAt
      questions {
        isCorrect
        question {
          title
          description
          answer {
            text
            isCorrect
            explainText
            explainImageOrVideo
          }
        }
      }
    }
  }
`;

export const SAVE_NATIONAL_TEST = gql`
  mutation SAVE_NATIONAL_TEST($input: SaveNationalTestInput!) {
    saveNationalTest(input: $input)
  }
`;

export const CONTINUE_NATIONAL_TEST = gql`
  mutation CONTINUE_NATIONAL_TEST($input: ContinueNationalTestInput!) {
    continueNationalTest(input: $input) {
      answers {
        answerId
        isCorrect
        questionId
      }
      nationalTestId
      questions {
        accountId
        answer {
          createdAt
          deletedAt
          explainImageOrVideo
          explainText
          id
          isCorrect
          questionId
          text
          updatedAt
        }
        categoryId
        chapterId
        createdAt
        deletedAt
        description
        file
        id
        identityNumber
        level
        no
        public
        schoolId
        status
        subject {
          code
          id
          name
        }
        subjectId
        title
        updatedAt
      }
      remainTimes
    }
  }
`;
