import { gql } from '@apollo/client';

export const GET_MY_EXAM = gql`
  query GetMyExam {
    getMyExam {
      data {
        endDate
        classes {
          classId
        }
        formTestId
        id
        sectionId
        startDate
      }
      total
    }
  }
`;

export const GET_MY_EXAMINATION = gql`
  query GetMyExam($data: Boolean) {
    getMyExam(todo: $data) {
      data {
        endDate
        classes {
          classId
        }
        formTestId
        id
        sectionId
        startDate
      }
      total
    }
  }
`;

export const GET_MINI_TEST_QUESTION = gql`
  mutation PerformExam($data: PerformMiniTestInput!) {
    performExam(data: $data) {
      miniTestId
      questions {
        accountId
        answer {
          explainImageOrVideo
          explainText
          isCorrect
          id
          questionId
          text
        }
        file
        categoryId
        chapterId
        createdAt
        deletedAt
        description
        id
        identityNumber
        level
        public
        schoolId
        status
        subjectId
        title
        updatedAt
        numberOfCorrect
      }
    }
  }
`;

export const SUBMIT_MINI_TEST_QUESTION = gql`
  mutation SubmitAnswerMiniTestQuestion($data: SubmitMiniTestInput!) {
    submitAnswerMiniTestQuestion(data: $data) {
      isCorrect
      question {
        answer {
          explainImageOrVideo
          explainText
          id
          isCorrect
          questionId
          text
        }
        id
        title
        description
      }
    }
  }
`;

export const GET_SECTIONS = gql`
  query GET_SECTIONS {
    sections {
      id
      name
    }
  }
`;
