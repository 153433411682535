export const isEqualArray = (array1: number[], array2: number[]) => {
  const sameArray =
    array1.length === array2.length && array1.every((value, index) => value === array2[index]);
  return sameArray;
};

export const formatPercent = (value: number) => {
  if (value === 0 || !value) return 0;

  if (value % 2 === 0) return value;

  return value.toFixed(2);
};

export const getRegexPassword = () => {
  const regex = /^[0-9a-zA-Z]{6,14}/;
  return regex;
};

export const listSubjectHardCode = [
  '関係法規・制度及び運営管理',
  '公衆衛生・環境衛生',
  '感染症',
  '衛生管理技術',
  '人体の構造及び機能',
  '皮膚科学',
  '香粧品化学',
  '文化論及び美容技術理論',
];

export const sortSubject = (array: any[]) => {
  return [...array].sort(
    (a, b) => listSubjectHardCode.indexOf(a.name) - listSubjectHardCode.indexOf(b.name),
  );
};
