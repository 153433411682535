import { Checkbox, Col, Form, FormInstance, Row } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import back from '../../../assets/images/back.svg';
import next from '../../../assets/images/next.svg';
import { Answer, QuestionType } from '../type';

import ModalImageQuestion from 'src/pages/QuestionTest/ModalImageQuestion/ModalImageQuestion';
import styles from './index.module.scss';

interface IQuestionProps {
  question: QuestionType;
  keyIdx: number;
  isShow: boolean;
  form: FormInstance<any>;
  backStep: () => void;
  nextStep: (questionSelected?: any) => void;
  notShowExplain?: boolean;
  isTest?: boolean;
  dataSubmit?: any;
  oldAnswer?: any;
  reload: boolean;
}

const Media = ({ url }: { url?: string }) => {
  if (!url) return null;

  // if (url.includes('mp4'))
  //   return (
  //     <video
  //       src={url?.startsWith('http') ? url : process.env.REACT_APP_API_ENDPOINT_UPLOAD + url}
  //     />
  //   );

  if (url.startsWith('http') && !url.includes('amazonaws')) {
    return (
      <a className={styles.link} href={url} target='_blank' rel='noreferrer'>
        詳細はこちら
      </a>
    );
  }

  return (
    <ModalImageQuestion
      imgUrl={url?.startsWith('http') ? url : process.env.REACT_APP_API_ENDPOINT_UPLOAD + url}
    >
      <img
        src={url?.startsWith('http') ? url : process.env.REACT_APP_API_ENDPOINT_UPLOAD + url}
        alt=''
      />
    </ModalImageQuestion>
  );
};

const ExplainComponent = ({ explainText }: any) => {
  if (!explainText) return <div className={styles.explainText}>解説はありません</div>;

  if (explainText?.startsWith('https'))
    return (
      <div className={styles.explainText}>
        <a href={explainText} target='_blank' rel='noreferrer' className={styles.link}>
          詳細はこちら
        </a>
      </div>
    );

  return (
    <div
      className={styles.explainText}
      dangerouslySetInnerHTML={{
        __html: explainText,
      }}
    />
  );
};

const FormItemCheckBoxQuestion = ({
  value = [],
  onChange,
  showCorrectOption,
  question,
  notShowExplain,
}: any) => {
  const MAX_ANSWER_CHOOSE_ABLE =
    question.answer.filter((item: Answer) => item.isCorrect === true).length ||
    question.numberOfCorrect;

  const onChangeVal = (val: any) => {
    const lastItem = val?.[val?.length - 1];
    // yes no question
    if (question.level === 1) {
      if (lastItem === value?.[0]) {
        return onChange([]);
      }
      return onChange([lastItem]);
    }

    if (val.length > MAX_ANSWER_CHOOSE_ABLE) return;
    onChange(val);
  };

  return (
    <Checkbox.Group
      value={value}
      disabled={showCorrectOption}
      className='radio-custom'
      onChange={onChangeVal}
    >
      <Row gutter={20} align='middle' justify={'space-between'} className={styles.answerList}>
        {question.answer.length === 2
          ? question.answer.map((item: any, idx: any) => (
              <Col key={idx} md={6} xs={12} className={styles.divCenter}>
                <Checkbox
                  key={idx}
                  value={item?.id}
                  // className={
                  //   item.isCorrect && showCorrectOption && !notShowExplain ? 'show-more' : undefined
                  // }
                >
                  <div className={styles.txtAnswer}>{item?.text || ''}</div>
                </Checkbox>

                {showCorrectOption && !notShowExplain && item.isCorrect ? (
                  <img src={process.env.PUBLIC_URL + '/images/correct-ans.png'} alt='' width={24} />
                ) : (
                  <div style={{ width: '24px', height: '24px' }} />
                )}
              </Col>
            ))
          : question.answer.map((item: any, idx: any) => (
              <Col key={idx} md={6} xs={12} className={styles.divCenter}>
                <Checkbox
                  key={idx}
                  value={item?.id}
                  // className={
                  //   item.isCorrect && showCorrectOption && !notShowExplain ? 'show-more' : undefined
                  // }
                >
                  <div className={styles.txtAnswer}>{idx + 1}</div>
                </Checkbox>
                {showCorrectOption && !notShowExplain && item.isCorrect ? (
                  <img
                    src={process.env.PUBLIC_URL + '/images/correct-ans.png'}
                    alt=''
                    width={24}
                    height={24}
                  />
                ) : (
                  <div style={{ width: '24px', height: '24px' }} />
                )}
              </Col>
            ))}
      </Row>
    </Checkbox.Group>
  );
};

const Question: React.FunctionComponent<IQuestionProps> = ({
  keyIdx,
  question,
  isShow,
  nextStep,
  backStep,
  notShowExplain,
  isTest,
  dataSubmit,
  form,
  oldAnswer,
  reload,
}) => {
  const [showCorrectOption, setShowCorrectOption] = useState(false);
  const valueAnswer = Form.useWatch([`question_${question?.id}_${question?.subjectId}`]);
  const isSelectedAnswer = valueAnswer?.filter(Boolean);
  useEffect(() => {
    if (oldAnswer) {
      form.setFieldValue(
        Object.keys(oldAnswer),
        Object.values(oldAnswer)?.map((it: any) => it)?.[0],
      );
    }
  }, [oldAnswer, form, isShow]);
  useEffect(() => {
    if (isTest && dataSubmit) {
      setShowCorrectOption(true);
    }
  }, [isTest, dataSubmit]);

  useEffect(() => {
    setShowCorrectOption(false);
  }, [reload]);

  const ansersCorrect = useMemo(() => {
    return question.answer?.find((answer) => answer.isCorrect);
  }, [question.answer]);

  if (!isShow) return <></>;

  return (
    <div className={styles.questionWrap}>
      <div className='index-que'>Q{keyIdx}</div>
      <div className='overall-question mb-4'>
        <div
          className={styles.contentQuestion}
          dangerouslySetInnerHTML={{
            __html: question?.title?.split('\n')?.join('<br />'),
          }}
        />
        {question?.description && (
          <div
            className={styles.contentQuestion}
            dangerouslySetInnerHTML={{
              __html: question?.description?.split('\n')?.join('<br />'),
            }}
          />
        )}
        {question?.file && (
          <div className={styles.mediaQuestion}>
            <Media url={question?.file ?? ''} />
          </div>
        )}
        <div className={styles.listAnswer}>
          {question.answer.map((item, idx) => (
            <div key={idx} style={{ display: 'flex', fontSize: '16px' }}>
              <div>{idx + 1}.&nbsp;</div>
              <div style={{ textAlign: 'left' }}>{item.text}</div>
            </div>
          ))}
        </div>
      </div>

      <Form.Item name={`question_${question?.id}_${question?.subjectId}`} preserve>
        <FormItemCheckBoxQuestion
          showCorrectOption={showCorrectOption}
          notShowExplain={notShowExplain}
          question={question}
        />
      </Form.Item>

      <div className={styles.btnActions}>
        {keyIdx !== 1 && keyIdx !== 55 ? (
          <button className={styles.btn} onClick={backStep}>
            <img src={back} alt='' />
            <div>&nbsp;戻る</div>
          </button>
        ) : (
          <div />
        )}

        <button
          className={styles.btn}
          onClick={() => {
            if (!notShowExplain) {
              showCorrectOption ? nextStep(question) : setShowCorrectOption(true);
            } else {
              nextStep(question);
            }
          }}
          disabled={isTest ? false : !isSelectedAnswer?.length}
        >
          <div>{keyIdx === 55 ? '回答を確認' : '次'}&nbsp;</div>
          <img src={next} alt='' />
        </button>
      </div>

      {showCorrectOption && !notShowExplain && (
        <div className={styles.explanation}>
          <div className={styles.explainTitle}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ fontSize: '11px', margin: '0 5px' }}>POINT</div>
              <img src={process.env.PUBLIC_URL + '/images/pencil.svg'} alt='' width={24} />
            </div>
            <div className={styles.titleCenter}> 解 説</div>
          </div>

          <div className={styles.explainBody}>
            <div>
              <Media url={ansersCorrect?.explainImageOrVideo ?? ''} />

              <ExplainComponent explainText={ansersCorrect?.explainText} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Question;
