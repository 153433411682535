import React from 'react'

const Examination = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 18.48 20.961">
      <g id="Group_909" data-name="Group 909" transform="translate(-72.859 -860.593)">
        <g id="グループ_6" data-name="グループ 6">
          <path id="Path_1166" data-name="Path 1166" d="M87.182,860.593H74.952a2.093,2.093,0,0,0-2.093,2.092v15.629a2.093,2.093,0,0,0,2.093,2.092h8.4a4.948,4.948,0,0,1-.343-4.758h-1.8a.674.674,0,0,1,0-1.349h2.733a4.926,4.926,0,0,1,3.563-1.517,4.85,4.85,0,0,1,1.77.329V862.685A2.093,2.093,0,0,0,87.182,860.593Zm-9.019,15.371a.63.63,0,0,1-.63.63h-1.6a.63.63,0,0,1-.631-.63v-1.6a.63.63,0,0,1,.631-.631h1.6a.63.63,0,0,1,.63.631Zm0-4.474a.631.631,0,0,1-.63.631h-1.6a.631.631,0,0,1-.631-.631v-1.6a.631.631,0,0,1,.631-.631h1.6a.631.631,0,0,1,.63.631Zm0-4.474a.63.63,0,0,1-.63.631h-1.6a.631.631,0,0,1-.627-.631v-1.6a.631.631,0,0,1,.631-.631h1.6a.631.631,0,0,1,.631.631Zm8.414,4.215H81.211a.674.674,0,0,1,0-1.349h5.366a.674.674,0,1,1,0,1.349Zm0-4.411H81.211a.674.674,0,0,1,0-1.349h5.366a.675.675,0,1,1,0,1.349Z" fill="#a5a072" />
        </g>
        <path id="パス_47" data-name="パス 47" d="M89.274,874.32a3.818,3.818,0,0,0-3.492-.027,3.872,3.872,0,0,0-1.5,1.354,3.794,3.794,0,0,0-.609,2.07,3.834,3.834,0,1,0,5.6-3.4ZM90,876.79l-2.914,2.834a.548.548,0,0,1-.386.156h-.04a.566.566,0,0,1-.4-.214l-1.27-1.644a.554.554,0,1,1,.846-.717.442.442,0,0,1,.033.043l.889,1.151,2.47-2.4a.555.555,0,0,1,.8.771L90,876.79Z" fill="#a5a072" />
      </g>
    </svg>
  )
}

export default Examination