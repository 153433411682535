import { Modal } from 'antd';
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './index.module.scss';

const ModalCloseExam = ({ children }: { children?: React.ReactNode }, ref: any) => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const refSubmit: any = useRef();

  useImperativeHandle(ref, () => {
    return {
      onOpen: () => setVisible(true),
      setSubmit: (func: any) => {
        refSubmit.current = func;
      },
    };
  });

  const onVisible = () => {
    setVisible(!visible);
  };

  const renderFooter = () => {
    return (
      <div className={styles.btnActions}>
        <div
          className={styles.buttonModal}
          onClick={() => {
            if (refSubmit.current) {
              refSubmit.current();
              setVisible(false);
              return;
            }
            navigate('/');
          }}
        >
          終了する
        </div>
        <div className={styles.buttonModal} onClick={onVisible}>
          キャンセルする
        </div>
      </div>
    );
  };

  return (
    <>
      {children && <span onClick={onVisible}>{children}</span>}
      <Modal
        className={styles.modalClose}
        title=''
        open={visible}
        footer={renderFooter()}
        onCancel={onVisible}
        closeIcon={<></>}
        centered
      >
        <p>テストを終了してもよろしいですか？</p>
        {/* <p>回答内容は保存されません。</p> */}
      </Modal>
    </>
  );
};

export default forwardRef(ModalCloseExam);
