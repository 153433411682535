import { useLazyQuery } from '@apollo/client';
import { useMount } from 'ahooks';
import { useState } from 'react';
import { GET_MY_NATIONAL_EXAM } from 'src/services/examination';
import { GET_MY_EXAM } from 'src/services/question-mini-test';
import { useModalClose } from 'src/store/modalCloseExam/useCloseExam';
import { useProfileStore } from 'src/store/profile/useProfileStore';

const useMyExam = () => {
  const [isShow, setShow] = useState(false);
  const { changeValueTest } = useModalClose();
  const { profile } = useProfileStore();
  const [getNationalExam, { data: dataNationalExam }] = useLazyQuery(GET_MY_NATIONAL_EXAM, {
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      changeValueTest({
        loading: false,
        value: data.getMyNationalExam,
      });
    },
  });

  const [getMyExam, { data }] = useLazyQuery(GET_MY_EXAM, {
    fetchPolicy: 'no-cache',
  });

  useMount(() => {
    (async () => {
      const data = await Promise.all([getMyExam(), getNationalExam()]);
      const [myExamData, myNationalExamData] = data;
      const localData = localStorage.getItem('lmsShowNoti');

      if (
        (myExamData?.data?.getMyExam?.total ||
          myNationalExamData?.data?.getMyNationalExam?.length) &&
        !localData
      ) {
        setShow(true);
      }

      if (
        !myExamData?.data?.getMyExam?.total &&
        !myNationalExamData?.data?.getMyNationalExam?.length
      ) {
        localStorage.removeItem('lmsShowNoti');
      }
    })();
  });

  const onCloseModal = (isButton?: boolean) => {
    if (isButton) {
      localStorage.setItem('lmsShowNoti', `${profile.id}`);

      setShow(false);
      return;
    }
    setShow(false);
  };

  return {
    isShow,
    onCloseModal,
    data,
    dataNationalExam,
  };
};

export default useMyExam;
