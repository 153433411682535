import React from 'react'

const MypageIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 25.546 22.287">
      <path id="Path_1168" data-name="Path 1168" d="M82.1,965.531a7.52,7.52,0,0,0-4.747,1.556c.524-1.548,2.45-2.7,4.747-2.7s4.223,1.148,4.747,2.7A7.52,7.52,0,0,0,82.1,965.531ZM73.579,981.9a6.49,6.49,0,0,1,1.094-1.694,9.039,9.039,0,0,1,2.552-1.531c.2-.092.394-.186.586-.279a3.9,3.9,0,0,1-2.928-1.262c-.524-.7-1.281-3.77-.142-8.111s4.072-6.815,6.666-6.913c2.548-.1,3.419,1.14,3.419,1.14a5.841,5.841,0,0,1,4.056,2.712c1.913,2.961,2.456,10.57-.188,12.048a5.074,5.074,0,0,1-2.057.506l.334.158a9.077,9.077,0,0,1,2.554,1.532,6.49,6.49,0,0,1,1.094,1.694,12.773,12.773,0,1,0-17.04,0Zm3.457-11.4a1.053,1.053,0,0,0-.12-.05,1.05,1.05,0,0,0-.029.213,1.709,1.709,0,0,0,.7,1.515,1.67,1.67,0,0,1,.258.261.629.629,0,0,1,.106.225,5.753,5.753,0,0,0,1.657,2.689,1.782,1.782,0,0,1,.5,1.231v.013a.388.388,0,0,1,0,.059A8.975,8.975,0,0,1,79.9,977.7c-.284,1.081-.62,1.374-.849,1.5-.1.057-.21.114-.32.171a5.845,5.845,0,0,0,6.734,0c-.11-.057-.218-.114-.321-.171-.228-.127-.565-.42-.848-1.5a8.81,8.81,0,0,1-.207-1.041q0-.03,0-.06v-.012a1.787,1.787,0,0,1,.5-1.231,5.753,5.753,0,0,0,1.657-2.689.644.644,0,0,1,.1-.225,1.724,1.724,0,0,1,.259-.261,1.709,1.709,0,0,0,.7-1.515,1.147,1.147,0,0,0-.028-.213.893.893,0,0,0-.124.052.531.531,0,0,1-.056.023c-.35.516-1.142.943-1.089.764a11.948,11.948,0,0,0,.2-1.958,4.762,4.762,0,0,1-2.612-2.442s-.541,2.444-3.879,2.858l.508-1.077-2.275.979a11.314,11.314,0,0,0,.076,1.64C78.057,971.475,77.376,971.019,77.036,970.5Z" transform="translate(-69.326 -959.612)" fill="#a5a072" />
    </svg>
  )
}

export default MypageIcon